import React, { useState } from "react"
import axios from "axios"
import Layout from "./../components/layout"
import PageHeader from "../components/pageHeader"
import casa from "../img/Casa1.jpg"
import { Button, Col, Container, Row } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

const ContactPage = () => {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/1690f91e-1c1c-4ad3-a014-54c983070f03",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return <Layout>
    <PageHeader backgroundImage={casa} title="About Us"/>
    <section className="section section_contact">
      <Container>
        <Row>
          <Col>
            <h2 className="section__heading text-center">
              Get in touch with us
            </h2>
            <p className="section__subheading text-center">
              Send us a message by email or using the form and we will get back to you as soon as possible. Also feel free to give us a call and one of our team members will help you.
            </p>

          </Col>
        </Row>
        <Row>
          <Col md={3} className="order-md-2">
            <div className="section_contact__info">
              <div className="section_contact__info__item">
                <h4 className="section_contact__info__item__heading">
                  Write us
                </h4>
                <p className="section_contact__info__item__content">
                  <a href="mailto:contact@elhumero.co.nz">contact@elhumero.co.nz</a>
                </p>
              </div>
              <div className="section_contact__info__item">
                <h4 className="section_contact__info__item__heading">
                  Call us
                </h4>
                <p className="section_contact__info__item__content">
                  <a href="tel:+6494899973">+64 9 4899973</a>
                </p>
              </div>
              <div className="section_contact__info__item">
                <h4 className="section_contact__info__item__heading">
                  Visit us
                </h4>
                <p className="section_contact__info__item__content">
                  3/40 Hurstmere Road, Takapuna, Auckland
                </p>
              </div>
              <div className="section_contact__info__item">
                <h4 className="section_contact__info__item__heading">
                  Social links
                </h4>
                <ul className="section_contact__info__item__content">
                  <li>
                    <a href="https://twitter.com/ElHumeroAkl">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/elhumeroakl">
                      <FontAwesomeIcon icon={faFacebookF}/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/elhumeroakl/">
                      <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={9} className="order-md-1">
            <form className="section_contact__form" onSubmit={handleOnSubmit}>
              <div className="form-group">
                <label htmlFor="name" className="sr-only">Full name</label>
                <input type="text" className="form-control" id="name" name="name"
                       placeholder="Full name"/>
                <div className="invalid-feedback"/>
              </div>
              <div className="form-group">
                <label htmlFor="email" className="sr-only">E-mail address</label>
                <input type="email" className="form-control" id="email" name="email"
                       placeholder="E-mail address"/>
                <div className="invalid-feedback"/>
              </div>
              <div className="form-group">
                <label htmlFor="message" className="sr-only">Message</label>
                <textarea className="form-control" id="message" name="message" rows="9"
                          placeholder="Message"/>
                <div className="invalid-feedback"/>
              </div>
              <Button type="submit" className="btn btn-primary" disabled={serverState.submitting}>
                Send message
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
}

export default ContactPage;
